import * as React from 'react';
import { useGlobalContext }from '../../context/GlobalContext';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header';
import HeroSection from '../../components/HeroSection';
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { Row, Col } from "react-bootstrap";
import Seo from "../../components/Seo";
import Section from '../../components/Section';
import SectionTitle from '../../components/Section/SectionTitle';
import SectionStepByStep from '../../components/SectionStepByStep';
import SectionScheduleAssessment from '../../components/SectionScheduleAssessment/SectionScheduleAssessment';
import Inbetweener from '../../components/Inbetweener';
import SingleCount from '../../components/SingleCount';

const Home = ({pageContext, data, location}) => {

  // init page metadata
  const { pages } = useGlobalContext();
  const thisPage = pages.getByPath(location.pathname);

  // retrieve bg image
  const {
    imageHero,
    imageLeadToCash,
    imageEventMesh,
    imageIntegrationSuite,
    imageArchitecture,
    imageInbox,
    imageProcess,
    imageWorkzone
   } = data

  const imgHero = getImage(imageHero);
  const imgLeadToCash = getImage(imageLeadToCash);
  const imgEventMesh = getImage(imageEventMesh);
  const imgIntegrationSuite = getImage(imageIntegrationSuite);
  const imgArchitecture = getImage(imageArchitecture);
  //const imgInbox = getImage(imageInbox);
  const imgMail = getImage(imageProcess);
  const imgWorkzone = getImage(imageWorkzone);
  
  // get breadcrumbs
  const {breadcrumb: { crumbs }} = pageContext

  return (
      <>
        <Seo title={thisPage?.Title} description={thisPage?.Description}/>

        <Header />

        {/* 
        HERO SECTION
        */}
        <HeroSection 
            title={thisPage?.Pagetitle}
            titleFullWidth={true}
            bgImage={imgHero}
            bgImagePosition="bottom"
            breadcrumbs={crumbs}
        />

        <Section id="our-customer" bgColor='light' verticalAlign='start'>
          <Col className="col-lg-5 col-12">
            <div className="content-above mb-7">
              <SectionTitle small>
                Our customer
              </SectionTitle>
              <p>
              Aperam is a global leader in stainless, electrical, and specialty steel production, with roots as a spin-off from ArcelorMittal and headquartered in Luxembourg. The company is renowned for its sustainable innovations and high-performance materials serving diverse industries.
              </p>
            </div>
          </Col>
          <Col className="col-lg-7 col-12">
              <Row className="align-items-stretch">
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>Industry</p>
                    <h3>Stainless steel, electrical steel and specialty steels</h3>
                  </div>
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>Size</p>
                    <h3>Large enterprise</h3>
                  </div>
                </Col>
                <div className="w-100"></div>
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>Number of employees</p>
                    <h3>More than 11,5k employees worldwide</h3>
                  </div>
                </Col>
                <Col className="col-lg-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <p>IT Landscape</p>
                    <h3>SAP BTP, SAP Build Process Automation, SAP CAP</h3>
                  </div>
                </Col>
              </Row>
          </Col>
        </Section>
        
        <Section id="use-case" bgColor='dark'>
          <Row>
            <Col className="col-lg-5 col-12">
              <div className="content-above mb-7">
                <SectionTitle>
                Use case: <br />
                Capex Investment Request Platform
                </SectionTitle>
                <div>
                  <p>Facilitate efficient capital expense management through real-time integration of a scalable CapEx investment request platform with SAP S/4HANA Public Cloud, ensuring clarity, compliance and operational excellence.</p>
                </div>
              </div>
            </Col>
            <Col className="col-lg-7 col-12 justify-content-center d-flex">
              <GatsbyImage style={{width:"auto", margin:"auto"}} image={imgWorkzone} alt="icons showing a Lead to Cash flow with SAP"/>
            </Col>
          </Row>
          <Row className="row counter__area counter__area-2 align-items-stretch">
            <Col className="col-lg-4 col-12">
              <div className="features__item-2">
                <SingleCount counter={4} duration={1} title="months project duration" color="blue-2-color" />
              </div>
            </Col>
            <Col className="col-lg-4 col-12">
              <div className="features__item-2">
                <SingleCount counter={3} duration={1} title="Aarini consultants involved" color="green-4-color" />
              </div>
            </Col>
            <Col className="col-lg-4 col-12">
              <div className="features__item-2">
                <SingleCount counter={4} duration={1} title="Real-time complex workflows" color="orange-color" />
              </div>
            </Col>
          </Row>
        </Section>
        
        <Section id="challenges" bgColor='light'>
          <SectionTitle align='center'>
            Challenges of the customer
          </SectionTitle>
          <Row>
            <Col ClassName="col-lg-4 col-12">
              <div className="features__item-2 h-100">
              <p className="fs-3">The current CapEx solution faces significant challenges, including limited scalability and lack of seamless integration with enterprise systems, resulting in inefficiencies in managing complex workflows. </p>
              </div>
            </Col>
            <Col ClassName="col-lg-4 col-12">
              <div className="features__item-2 h-100">
              <p className="fs-3">Additionally, the solution lacks advanced reporting and analytics capabilities, hindering the tracking of investment performance and ROI. </p>
              </div>
            </Col>
            <Col ClassName="col-lg-4 col-12">
              <div className="features__item-2 h-100">
              <p className="fs-3">Insufficient role-based access controls further adds potential security and compliance risks, while the system struggles to scale for large-scale, multi-departmental CAPEX processes in growing organizations. </p>
              </div>
            </Col>
          </Row>
        </Section>

        <Section id="solution" bgColor='dark' verticalAlign='start'>
          <Col className="col-lg-5 col-12">
            <div className="content-above mb-7">
              <SectionTitle small>
                Solution
              </SectionTitle>
              <div>
              <p>The SAP BTP-based solution addresses the limitations of the current CapEx system by enabling scalable and efficient capital expenditure management. It streamlines request submissions, multi-level approvals, and audit tracking with customizable templates and predefined business rules. With enhanced integration, real-time reporting, and flexibility for Super Users, the platform ensures transparency, compliance, and effective budget control.</p>
                </div>
            </div>
          </Col>
          <Col className="col-lg-7 col-12">
              <Row className="align-items-stretch">
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100 wow">
                    <GatsbyImage className="mb-4" image={imgIntegrationSuite} alt="Icon representing SAP Integration Suite" />
                    <h3>SAP Build Process Automation</h3>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <GatsbyImage className="mb-4" image={imgEventMesh} alt="Icon representing SAP Mesh Events" />
                    <h3>SAP CAP</h3>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100">
                    <GatsbyImage className="mb-4" image={imgEventMesh} alt="Icon representing SAP Mesh Events" />
                    <h3>SAP UI5</h3>
                  </div>
                </Col>
                <Col className="col-xl-6 col-12 mb-3">
                  <div className="features__item-2 h-100 wow">
                    <GatsbyImage className="mb-4" image={imgIntegrationSuite} alt="Icon representing SAP Integration Suite" />
                    <h3>Cloud Foundry</h3>
                  </div>
                </Col>
              </Row>
          </Col>
        </Section>

        <Section id="solution" bgColor='light'>
          <GatsbyImage image={imgArchitecture} alt="Icon representing SAP integrations" style={{width:"auto", margin:"auto"}}/>
        </Section>
        <Section id="solution" bgColor='light'>
          <GatsbyImage image={imgMail} alt="Icon representing SA" style={{width:"auto", margin:"auto"}}/>
        </Section>

        {/* 
        Inbetweener
         */}
        <Inbetweener bgColor='dark' textColor='dark'>
        Empower smart investments with scalable and integrated CapEx solutions for a future-ready enterprise! 
        </Inbetweener>

        {/* 
        SectionStepByStep
         */}
        <SectionStepByStep bgColor='white'
          title="Benefits of SAP Build Process Automation"
          checkItems={[
            "Higher employee satisfaction due to simplified processes.",
            "Improved integration with SAP S/4HANA.",
            "Streamlined workflows and approval processes.",
            "Reduced manual effort and error rates.",
            "Enhanced data accuracy and reporting.",
            "Faster response time for investment decisions.",
          ]}
          showButton={false}
        />
         
        {/*
        SCHEDULE SESSION
        */}
        <SectionScheduleAssessment 
          bgColor='dark' 
          topics='sap-btp'
          title="Interested? Schedule an SAP BTP exploration and advisory session"
          subtitle="This is what we will cover during our first (online) meeting."
          items={[]}
          buttonText='Plan an exploration and advisory session'
        />
        
        <Footer />
      </>
  );
};

export default Home;

export const query = graphql`
  query Home {
    imageHero: file(relativePath: { eq: "aperambgblack4.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          height: 300
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 100 }
        )
      }
    }
    imageLeadToCash: file(relativePath: { eq: "sap-diagram-lead-to-cash.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageEventMesh: file(relativePath: { eq: "sap_icon_event mesh.png" }) {
      childImageSharp {
        gatsbyImageData(
          height: 80
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageIntegrationSuite: file(relativePath: { eq: "sap_icon_integration_suite.png" }) {
        childImageSharp {
        gatsbyImageData(
          height: 80
          quality: 50
          placeholder: BLURRED
          webpOptions: { quality: 70 }
        )
      }
    }
    imageArchitecture: file(relativePath: { eq: "aperam_architecture_diagram.png" }) {
        childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 100
          placeholder: BLURRED
          webpOptions: { quality: 100 }
        )
      }
    }
    imageProcess: file(relativePath: { eq: "aperam_process.png" }) {
        childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 100
          placeholder: BLURRED
          webpOptions: { quality: 100 }
        )
      }
    }
    imageWorkzone: file(relativePath: { eq: "aperam_workzone.jpeg" }) {
        childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 100
          placeholder: BLURRED
          webpOptions: { quality: 100 }
        )
      }
    }
  }`